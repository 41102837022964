/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

// import Vue from 'vue'
// import App from '../app.vue'

import TurbolinksAdapter from "vue-turbolinks";
import Vue from "vue/dist/vue.esm.js";
import App from "../app.vue";
import Buefy from "buefy";
//import 'buefy/dist/buefy.css'
import "../stylesheets/styles.scss";
import "../stylesheets/estilos";

import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import setupCSRF from "./setupCSRF";
import store from "../store";
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import Multiselect from "vue-multiselect";

library.add(fas);
Vue.component("vue-fontawesome", FontAwesomeIcon);
Vue.component("multiselect", Multiselect);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
});
Vue.use(TurbolinksAdapter);

import {
  required,
  email,
  min_value,
  max_value,
  integer,
  numeric,
  min,
  max,
  regex,
  alpha_num,
  length,
  alpha_dash,
} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Este campo es requerido",
});

extend("max", {
  ...max,
  message: "Este campo excede el límite de caracteres permitidos",
});

extend("integer", {
  ...integer,
  message: "Este campo requiere un valor numerico",
});

extend("alpha_num", {
  ...alpha_num,
  message: "Este campo solo admite caracteres alfa numéricos",
});

extend("max_value", {
  ...max_value,
  message: "El valor ingresado excede el límite esperado",
});

extend("min_value", {
  ...min_value,
  message: "El valor ingresado es inferior al valor minimo esperado",
});

extend("regex", {
  ...regex,
  message: "El formato ingresado no es valido",
});

extend("email", {
  ...email,
  message: "El email ingresado no es valido",
});

extend("positive", {
  message: () => "El número debe ser positivo",
  validate: (value) => {
    return value >= 0;
  },
});
extend("year", {
  message: () => "El año debe ser válido",
  validate: (value) => {
    return value >= 1840 && value <= 2100;
  },
});
extend("semester", {
  message: () => "El semestre debe ser válido",
  validate: (value) => {
    return value >= 1 && value <= 2;
  },
});

extend("section", {
  message: () => "Semestre debe seguir el formato: XX-YY",
  validate: (value) => {
    let splitted = value.split("-");
    if (splitted.length !== 3) return false;
    let isCod = !isNaN(splitted[0]);
    let isCoord = /^[a-zA-Z]+$/.test(splitted[1]);
    let isSection = !isNaN(splitted[2]);
    return isCoord && isSection;
  },
});

extend("day", {
  message: () => "El día debe ser válido",
  validate: (value) => {
    return value >= 1 && value <= 31;
  },
});

extend("month", {
  message: () => "El mes debe ser válido",
  validate: (value) => {
    return value >= 1 && value <= 12;
  },
});

extend("alphabet", {
  message: () =>
    "El nombre no es válido (contiene números o carácteres especiales)",
  validate: (value) => {
    // Regex para detectar carácteres especiales
    const formatSpecialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const formatNumbers = /\d/;
    return !formatSpecialCharacters.test(value) && !formatNumbers.test(value);
  },
});

extend("career_length", {
  message: () => "El nombre de carrera es muy corto (mínimo 10)",
  validate: (value) => {
    return value.length > 10;
  },
});

extend("file", {
  validate: (file) => {
    if (file === null) return true;
    const fileExtension = file.name.split(".").at(-1).toLowerCase();
    const isValidFormat = ["pdf"].includes(fileExtension);
    if (!isValidFormat)
      return "Formato del archivo no está permitido (sólo se admite .pdf)";
    // 10 MB
    if (file.size > 15000000)
      return "El tamaño del archivo es mayor al máximo permitido (10MB)";
    return true;
  },
});

extend("files", {
  validate: (files) => {
    let fileExtension;
    let isValidFormat;
    for (const file of files) {
      fileExtension = file.name.split(".").at(-1).toLowerCase();
      isValidFormat = ["pdf"].includes(fileExtension);
      if (!isValidFormat)
        return (
          "Formato del archivo " +
          file.name +
          " no está permitido (sólo se admite .pdf)"
        );
      // 10 MB
      if (file.size > 15000000)
        return (
          "El tamaño del archivo  " +
          file.name +
          " es mayor al máximo permitido (10MB)"
        );
    }
    return true;
  },
});

extend("orCheckbox", {
  validate(value, {boolean}) {
    if (value || boolean === "true") return true;
    return "Debe seleccionar al menos una opción";
  },
  params: ["boolean"],
});

extend("emailUsach", {
  validate: (email) => {
    let emailSplit = email.split("@");
    if (emailSplit.length !== 2) return "Error en el formato del correo";
    if (emailSplit[1].toLowerCase() !== "usach.cl")
      return "El correo debe ser @usach.cl";
    return true;
  },
});

extend('conditional', {
  params: ['field', 'fieldName'],
  validate: (value, {field, fieldName}) => {
    if (field) {
      return `Este campo quiere que ${fieldName} no sea completado.`;
    }
    return true;
  },
});

// Componentes
const welcome = () => import( "../components/welcome.vue")
const landing_page = () => import( "../components/landing_page.vue")

const PageSidebar = () => import( "../components/PageSidebar.vue")
import "../stylesheets/styles.scss";
const ProgramTable = () => import( "../components/programs/ProgramTable.vue")
const ProgramDataTable = () => import( "../components/programs/ProgramDataTable.vue")
const ModalProgram = () => import( "../components/programs/ModalProgram.vue")
const CreateUserModal = () => import( "../components/person/CreateUserModal.vue")

// upload
const Upload = () => import( "../components/programs/uploadProgram.vue")

//students
const Students = () => import( "../components/student/Students.vue")
const Student_find_rut = () => import( "../components/student/StudentFindRut.vue")
const Alumnos_find_rut = () => import( "../components/student/AlumnoFindRut.vue")
const CarrerasAlumno = () => import( "../components/student/CarrerasAlumno")
const FindDocument = () => import( "../components/student/FindDocument")
const CreateStudent = () => import( "../components/student/CreateStudent")
const CreateCareer = () => import( "../components/career/CreateCareer")
const AggregateCareer = () => import( "../components/student/AggregateCareer")
const ModalVistaDoc = () => import( "components/student/ModalVistaDoc")
const ModalVistaDocNull = () => import( "components/student/ModalVistaDocNull")
const ModalEditDoc = () => import( "components/student/ModalEditDoc")
const AllDocument = () => import( "../components/documents/AllDocument")
const Titulacion = () => import( "../components/documents/Titulacion")
const ControlCurricular = () => import( "../components/documents/ControlCurricular")
const InactiveDocument = () => import( "../components/documents/InactiveDocument")
const AdminPerson = () => import( "../components/student/AdminPerson")
const EditPersonDB = () => import( "../components/student/EditPersonDB")
const StudentCalificationActs = () => import( "../components/student/StudentCalificationActs.vue")

//Programs
const ImportDataDb = () => import( "../components/programs/ImportDataDB.vue")
const NewProgramSubject = () => import( "../components/programs/NewProgramSubject.vue")
const NewProgramSubjectPartTwo = () => import( "../components/programs/NewProgramSubjectPartTwo.vue")
const ControlPrograms = () => import( "../components/programs/ControlPrograms.vue")
//Subjects
const Subjects = () => import( "../components/subjects/searchSubjects.vue")
const SubjectShow = () => import( "../components/subjects/subjectShow.vue")

//Programs
const ShowProgramVersions = () => import( "../components/programs/show_program_versions.vue")
const IndexPaperTrailPrograms = () => import( "../components/programs/IndexPaperTrailPrograms.vue")
const ShowProgramTokens = () => import( "../components/programs/ShowProgramTokens.vue")
const PublicToken = () => import( "../components/programs/PublicToken.vue")
const PreviewProgramModal = () => import( "../components/programs/PreviewProgramModal.vue")

//Documents
const ManagmentProcess = () => import( "../components/documents/ManagmentProcess")
const MasiveImport = () => import( "../components/documents/MasiveImport")
const IndexPaperTrailLifeRecords = () => import( "../components/documents/IndexPaperTrailLifeRecords.vue")
const ShowLifeRecordVersions = () => import( "../components/documents/show_life_record_versions.vue")
const NewModalDocumentType = () => import( "../components/documents/NewModalDocumentType")
const NewModalTagTypeDocument = () => import( "../components/documents/NewModalTagTypeDocument")
const NewModalDocumentProcess = () => import( "../components/documents/NewModalDocumentProcess")
const TypeDocumentIndex = () => import( "../components/documents/TypeDocumentIndex")
const TagTypeDocumentIndex = () => import( "../components/documents/TagTypeDocumentIndex")
const EditModalTagTypeDocument = () => import( "../components/documents/EditModalTagTypeDocument")
const EditModalDocumentType = () => import( "../components/documents/EditModalDocumentType")

//Admin
const ProfilesTabs = () => import( "../components/admin/ProfilesTabs")
const DocumentsTabs = () => import( "../components/admin/DocumentsTabs")
const AdminIndicators = () => import( "../components/admin/AdminIndicators.vue")
const FailedDocuments = () => import( "../components/admin/documents/FailedDocuments.vue")

//Career
const AdminCareer = () => import( "../components/career/AdminCareer")
const EditCareerDB = () => import( "../components/career/EditCareerDB")

//Section
const ActSection = () => import( "../components/section/CalificationActs")
const SectionCalificationActs = () => import( "../components/section/SectionCalificationActs")
const SearchCalificationAct = () => import( "../components/section/SearchCalificationAct")
const CalificationActByActNumber = () => import( "../components/section/CalificationActByActNumber.vue")
const ShowCalificationActVersion = () => import( "../components/section/ShowCalificationActVersion.vue")
const ControlCalificationActs = () => import( "../components/section/ControlCalificationActs.vue")

//User
const EnableUsers = () => import( "../components/person/EnableUsers.vue")

// FIRMA DIGITAL
const PruebasFirma = () => import( "../components/firma_digital/FirmaDigital.vue")

const UpdateDrive = () => import( "../components/programs/UpdateDrive.vue")

// Place
const PlaceSelectorModal = () => import( "../components/place/PlaceSelectorModal.vue")

// Reporteria document
const DocumentsControl = () => import( "../components/documents/DocumentsControl.vue")
// Indicadores document
const IndicatorsLifeRecord = () => import( "../components/documents/IndicatorsLifeRecord.vue")

// Dashboard actas
const CalificationActsControl = () => import( "../components/section/CalificationActsControl.vue")

const MainProcessIndex = () => import( "../components/documents/MainProcessIndex.vue")

// Copia de programas
const CopyProgram = () => import( "../components/programs/CopyProgram.vue")
const IndexCopiedPrograms = () => import( "../components/programs/IndexCopiedPrograms.vue")

// Import masivo de programas
const MassiveProgramImport = () => import( "../components/programs/MassiveProgramImport.vue")

const ControlProgramData = () => import( "../components/programs/ControlProgramData.vue")

document.addEventListener("turbolinks:load", () => {
  setupCSRF();
  const app = new Vue({
    store,
    el: "#app",
    data: () => {
      return {
        message: "Can you say hello?",
      };
    },
    components: {
      App,
      welcome,
      landing_page,
      PageSidebar,
      Students,
      Student_find_rut,
      Alumnos_find_rut,
      CarrerasAlumno,
      FindDocument,
      CreateStudent,
      CreateCareer,
      AdminCareer,
      AdminPerson,
      EditPersonDB,
      EditCareerDB,
      AggregateCareer,
      ModalVistaDoc,
      ModalVistaDocNull,
      ModalEditDoc,
      ControlCurricular,
      AllDocument,
      InactiveDocument,
      Titulacion,
      ProgramTable,
      ProgramDataTable,
      ModalProgram,
      Subjects,
      SubjectShow,
      Upload,
      EnableUsers,
      ImportDataDb,
      NewProgramSubject,
      NewProgramSubjectPartTwo,
      ManagmentProcess,
      ProfilesTabs,
      MasiveImport,
      IndexPaperTrailLifeRecords,
      ShowLifeRecordVersions,
      IndexPaperTrailPrograms,
      ShowProgramVersions,
      NewModalDocumentType,
      NewModalTagTypeDocument,
      NewModalDocumentProcess,
      TypeDocumentIndex,
      TagTypeDocumentIndex,
      EditModalTagTypeDocument,
      EditModalDocumentType,
      DocumentsTabs,
      SectionCalificationActs,
      ActSection,
      SearchCalificationAct,
      CalificationActByActNumber,
      ShowCalificationActVersion,
      ShowProgramTokens,
      PublicToken,
      ControlCalificationActs,
      ControlPrograms,
      PruebasFirma,
      UpdateDrive,
      StudentCalificationActs,
      PreviewProgramModal,
      PlaceSelectorModal,
      CreateUserModal,
      DocumentsControl,
      CalificationActsControl,
      MainProcessIndex,
      CopyProgram,
      IndexCopiedPrograms,
      MassiveProgramImport,
      ControlProgramData,
      IndicatorsLifeRecord,
      FailedDocuments,
      AdminIndicators
    },
  });
});

// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>

//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
